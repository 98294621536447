import React from "react";
import { StaticQuery, graphql } from "gatsby";
import RenderContent from "../components/RenderContent";
import Layout from "../components/Layout";
import SEO from "../components/seo";

import linkedInLogo from "../images/linkedin-logo.png"
import anitaProfile from "../images/anita-profile.jpg"

import "./styles/about.css";

const Link = ({href}) => {
  return <a className="about-link" href={href}>Link</a>
}

const About = () => (
  <Layout selectedPage="about">
    <SEO title="about" />
    <StaticQuery
      query={graphql`
        query {
          prismicAbout {
            data {
              title {
                text
              }
              body {
                __typename
                ... on PrismicAboutBodyParagraph {
                  id
                  slice_type
                  primary {
                    text {
                      text
                    }
                    style
                  }
                }
              }
            }
          }
        }
      `}
      render={results => {
        const { title = "", body = [] } = results.prismicAbout.data;
        return (
          <section >
            <h3>{title.text}</h3>
            <div className="wrapper">
              <img className="profile-image" src={anitaProfile } alt="Anita Headshot" />
              <div className="content">
                <RenderContent content={body} />
                <p className="paragraph">
                  Anita's work is shaped and informed by the following research proven techniques :
                  <ul>
                    <li>Dr Ross W Greene - Collaborative and Proactive Solutions <Link href="https://www.livesinthebalance.org/"/></li>
                    <li>DBT - Dialectical Behavioural Therapy Skills <Link href="https://behavioraltech.org/resources/faqs/dialectical-behavior-therapy-dbt/"/></li>
                    <li>Mindfulness Practices - MBSR <Link href="https://www.mindfulnesscds.com/"/></li>
                    <li>Dr Dan Siegel - Mindsight <Link href="https://www.drdansiegel.com/"/></li>
                  </ul>
                </p>
                <a href=" https://www.linkedin.com/in/anita-thomson-profile/"><img className="linkedin-logo" src={linkedInLogo } alt="linkedIn Profile" /></a>
              </div>
            </div>
          </section>
        );
      }}
    />
  </Layout>
);

export default About;
